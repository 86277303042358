<template>
    <div>        
        <div id="contentToPrint">
            <h1>My Web Page</h1>0
            <p>This is the section you want to print.</p>
        </div>
    
        <button id="printButton" @click="generatePdf">Print Section</button>
        <iframe ref="pdfIframe" style="width: 100%; height: 600px;" frameborder="1"></iframe>
    </div>
</template>
  
<script>
// import axios from 'axios';
export default {
    data() {
        return {
            app_URL: process.env.VUE_APP_URL,

        }
    },
    methods: {


        generatePdf() {
            const printButton = document.getElementById('printButton');
            const contentToPrint = document.getElementById('contentToPrint');

            // Add a click event listener to the print button
            printButton.addEventListener('click', function () {
                // Create a new window for printing
                const printWindow = window.open('', '', 'width=600,height=600');

                // Add the content you want to print to the new window
                printWindow.document.write('<html><head><title>Print Section</title></head><body>');
                printWindow.document.write(contentToPrint.innerHTML);
                printWindow.document.write('</body></html>');

                // Close the document and initiate the print
                printWindow.document.close();
                printWindow.print();
                printWindow.close();
            });

        },
    }
}
</script>